import React from 'react';
import pizza from '../media/readypizza.png';
import pasta from '../media/nudeln_pesto.png';
import pommes from '../media/pommes.png';
import bbq from '../media/BBQ.png';
import springrolles from '../media/springrolles.png';
import ruebli from '../media/ruebli.png';
import quiche from '../media/quiche.png';
import zimtkuchen from '../media/zimtkuchen.png';
import gimbap from '../media/gimbap.png';
import tortelini from '../media/tortelini.png';

const QUESTION_DATA = [
  {
    question: <span>Carrot-Cake</span>,
    image: ruebli,
    details: <span>A cake that has fewer carbohydrates than others because it is baked without flour. It contains especially many nuts.
    The Frosting consists of icing sugar and cream cheese</span>,
    gram: <span>91g</span>,
    answers: [
      <span>6g Carbs</span>,
      <span>24g Carbs</span>,
      <span>53g Carbs</span>,
      <span>124g Carbs</span>,
    ],
    correct: 1
  },
  {
    question: <span>1.5 spring rolls</span>,
    image: springrolles,
    details: <span>The spring rolls are made of rice paper and contain glass noodles and carrots. Additionally the bowl contains rice noodles, which
      have a very high glycemic index</span>,
    gram: <span>338g</span>,
    answers: [
      <span>48g carbohydrates</span>,
      <span>24g carbohydrates</span>,
      <span>53g carbohydrates</span>,
      <span>124g carbohydrates</span>,
    ],
    correct: 0
  },
      {
        question: <span>Pasta salad with baguette and sausage</span>,
        image: bbq,
        details: <span>noodle salad with baguette, vegetables and grilled sausage. Sauces or ketchup are not included in this dish.</span>,
        gram: <span>269g</span>,
        answers: [
        <span>39g carbohydrates</span>,
        <span>94g carbohydrates</span>,
        <span>84g carbohydrates</span>,
        <span>77g carbohydrates</span>
        ],
        correct: 3
      },
      {
        question: <span>Chips & Croquettes</span>,
        image: pommes,
        details: <span>These Rewe fries and croquettes have about the same amount of carbohydrates per 100g.
        The portion on the photo is relatively large and is enough for about two small portions</span>,
        gram: <span>294g</span>,
        answers: [
        <span>39g carbohydrates</span>,
        <span>94g carbohydrates</span>,
        <span>184g carbohydrates</span>,
        <span>73g carbohydrates</span>
        ],
        correct: 3
        },
        {
          question: <span> 1/8 quiche</span>,
          image: quiche,
          details: <span>The quiche consists of a lot of vegetables and is held by a layer of flour dough. Other ingredients are eggs and sour cream</span>,
          gram: <span>245g</span>,
          answers: [
          <span>39g carbohydrates</span>,
          <span>53 g carbohydrates</span>,
          <span>22g carbohydrates</span>,
          <span>8g carbohydrates</span>
          ],
          correct: 2
          },
          {
            question: <span>pizza (stone oven)</span>,
            image: pizza,
            details: <span>Pizzas consist of many carbohydrates due to the high amount of dough. But they also contain a lot of fat due to the cheese and
            in this case additionally because of the salami and ham. A combination that makes the behaviour of the blood sugar level more complicated</span>,
            gram: <span>350g</span>,
            answers: [
            <span>39g carbohydrates</span>,
            <span>94g carbohydrates</span>,
            <span>84g carbohydrates</span>,
            <span>77g carbohydrates</span>
            ],
            correct: 2
            },
            {
              question: <span> pasta with pesto</span>,
              image: pasta,
              details: <span>A very simple and quick dish. 250g cooked
              Ribbon noodles made from durum wheat semolina and two eggs with Brailla Pomodoro Secchi Pesto and in addition a tomato mozzarella salad.</span>,
              gram: <span>250g</span>,
              answers: [
              <span>39g carbohydrates</span>,
              <span>94g carbohydrates</span>,
              <span>184g carbohydrates</span>,
              <span>77g carbohydrates</span>
              ],
              correct: 3
            },
            {
              question: <span>gimbap</span>,
              image: gimbap,
              details: <span><p>Gimbap (also Kimbap, Kim-Bab, Gim-Bab) is a popular Korean snack that is sometimes also called
              lunch is consumed. It consists of dried purple kelp (cor.: gim) and rice (cor. Bab =
              cooked rice), as well as, depending on the variation, other ingredients such as bulgogi, spinach, pickled radish
              (cor.: Danmuji 단무지) and omelette. The Korean "gim" is very similar in colour and taste to the Japanese "Nori".</p>
              <br/>
              <p>
              Most of the time.
              the rice has been cooked very softly, which gives it a
              higher glycaemic index. This means that the sugar gets into the blood very quickly.</p> </span>,
              gram: <span>370g</span>,
              answers: [
              <span>121g carbohydrates</span>,
              <span>24g carbohydrates</span>,
              <span>91g carbohydrates</span>,
              <span>27g carbohydrates</span>
              ],
              correct: 2
              },
              {
                question: <span>Tortellini</span>,
                image: tortelini,
                details: <span>Tortellini with cheese filling, zucchini, tomatoes and mushrooms</span>,
                gram: <span>125g</span>,
                answers: [
                <span>39g carbohydrates</span>,
                <span>95g carbohydrates</span>,
                <span>54g carbohydrates</span>,
                <span>77g carbohydrates</span>
                ],
                correct: 0
              },

              {
                question: <span>cinnamon wreath</span>,
                image: zimtkuchen,
                details: <span>The cinnamon wreath is a modified French roll recipe with spelt flour, wheat flour, cinnamon and brown sugar</span>,
                gram: <span>108g</span>,
                answers: [
                <span>22g carbohydrates</span>,
                <span>65g carbohydrates</span>,
                <span>56g carbohydrates</span>,
                <span>87g carbohydrates</span>
                ],
                correct: 2
              }

];

export default QUESTION_DATA;
