import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const Answer = ({ answer, handleAnswerClick, handleEnterPress }) => {
  return (



          <li
              className="question-answer"
              tabIndex="0"
              onClick={handleAnswerClick}
              onKeyDown={handleEnterPress}
               >

                  {answer}
          </li>

  );
}

Answer.propTypes = {
  answer: PropTypes.element.isRequired,
  handleAnswerClick: PropTypes.func.isRequired,
  handleEnterPress: PropTypes.func.isRequired
};

export default Answer;
