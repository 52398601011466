import React, {Fragment, Component, useEffect} from 'react';
import PropTypes from 'prop-types';
import Quiz from './Quiz';
import Modal from './Modal';
import Results from './Results';
import shuffleQuestions from '../helpers/shuffleQuestions';
import QUESTION_DATA from '../data/quiz-data';
import QUESTION_DATA_EN from '../data/quiz-data_en';
import { withNamespaces } from 'react-i18next';
import i18n from '../i18n';
import i18next from "i18next";

import Container from "@material-ui/core/Container";
import AnswerSnackBar from "./SnackBar";
import ReactPixel from 'react-facebook-pixel';
class QuizApp extends Component {


  state = {
    ...this.getInitialState(this.props.totalQuestions),
    wrong: false
  };

  static propTypes = {
    totalQuestions: PropTypes.number.isRequired
  };

  getInitialState(totalQuestions) {

    const QuestionLng = i18next.languages[0] === "en" ? QUESTION_DATA_EN :  QUESTION_DATA
    totalQuestions = Math.min(totalQuestions, QuestionLng.length);
    const QUESTIONS = shuffleQuestions(QuestionLng).slice(0, totalQuestions);

    return {
      questions: QUESTIONS,
      totalQuestions: totalQuestions,
      userAnswers: QUESTIONS.map(() => {
        return {
          tries: 0
        }
      }),
      step: 1,
      score: 0,
      wrong: false,
      modal: {
        state: 'hide',
        praise: '',
        points: ''
      }
    };
  }

  handleAnswerClick = (index) => (e) => {
    const { questions, step, userAnswers } = this.state;
    const isCorrect = questions[0].correct === index;
    const currentStep = step - 1;
    const tries = userAnswers[currentStep].tries;

    if (isCorrect && e.target.nodeName === 'LI') {
      // Prevent other answers from being clicked after correct answer is clicked
      e.target.parentNode.style.pointerEvents = 'none';

      e.target.classList.add('right');

      userAnswers[currentStep] = {
        tries: tries + 1
      };

      this.setState({
        userAnswers: userAnswers,
        wrong: false
      });

      setTimeout(() => this.showModal(tries), 750);

      setTimeout(this.nextStep, 2050);




    }

    else if (e.target.nodeName === 'LI') {
      e.target.style.pointerEvents = 'none';
      e.target.classList.add('wrong');

      userAnswers[currentStep] = {
        tries: tries + 1
      };

      this.setState({
        userAnswers: userAnswers,
        wrong: true
      });
    console.log(this.state.wrong)

    }
  };

  handleEnterPress = (index) => (e) => {
    if (e.keyCode === 13) {
      this.handleAnswerClick(index)(e);
    }
  };

  showModal = (tries) => {
    let praise;
    let points;
    const { t } = this.props;
    switch (tries) {
      case 0: {
        praise = t('1try');
        points = '+10';
        break;
      }
      case 1: {
        praise = t('2try');
        points = '+5';
        break;
      }
      case 2: {
        praise = t('3try');
        points = '+2';
        break;
      }
      default: {
        praise = t('4try');
        points = '+1';
      }
    }

    this.setState({
      modal: {
        state: 'show',
        praise,
        points
      }
    });

    window.scrollTo(0, 0)
  };

  nextStep = () => {
    const { questions, userAnswers, step, score } = this.state;
    const restOfQuestions = questions.slice(1);
    const currentStep = step - 1;
    const tries = userAnswers[currentStep].tries;

    this.setState({
      step: step + 1,
      score: this.updateScore(tries, score),
      questions: restOfQuestions,
      modal: {
        state: 'hide'
      }
    });
  };

  updateScore(tries, score) {
    switch (tries) {
      case 1: return score + 10;
      case 2: return score + 5;
      case 3: return score + 2;
      default: return score + 1;
    }
  }

  restartQuiz = () => {
    ReactPixel.track('Restart', {
      content_name: 'Restart lower average',
         })
    this.setState({
      ...this.getInitialState(this.props.totalQuestions)

    });
  };


  render() {
    const { step, questions, userAnswers, totalQuestions, score, modal, wrong } = this.state;
    const { t } = this.props;
    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
    this.restartQuiz()
    }

    if (step >= totalQuestions + 1) {  // step >= totalQuestions + 1
      return (
        <Results
          score={score}
          restartQuiz={this.restartQuiz}
          userAnswers={userAnswers}
          t={t}
        />
      );
    } else return (
      <Fragment>
        <Container maxWidth="md" style={{paddingLeft:8, paddingRight:8}}>
        {/*<button onClick={() => changeLanguage('de')}>de</button>*/}
        {/*<button onClick={() => changeLanguage('en')}>en</button>*/}

        <Quiz
          step={step}
          questions={questions}
          totalQuestions={totalQuestions}
          score={score}
          handleAnswerClick={this.handleAnswerClick}
          handleEnterPress={this.handleEnterPress}
          t={t}
        />
        </Container>
        <div  style={{height: 140}}></div>

        { wrong ?  <AnswerSnackBar t={t} open={true}/> : null}
        { modal.state === 'show' && <Modal modal={modal}
        /> }
      </Fragment>

    );
  }
}

export default withNamespaces()(QuizApp);
