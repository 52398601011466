import React from 'react';
import {makeStyles, responsiveFontSizes} from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import {red} from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {Box} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {

        width: "100%",
        borderRadius: "8px",
paddingTop: 0,
        paddingBottom:0
    },
    title: {
fontSize: 16,
        padding:0

    },
content:{padding: 0},

    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
}));

const styles = {

    overlay: {
        position: 'absolute',
        bottom: '5%',
        right: '20px',
        color: '#fff',
        fontSize: '20px',
        textAlign: 'right'

    },
    overlaybg: {
        position: 'absolute',
        bottom: '0px',
        right: '0px',
        color: '#fff',
        width: '100%',
        height: '130px',
        background: 'linear-gradient(0deg, rgba(2,0,36,0.4861519607843137) 0%, rgba(0,212,255,0) 100%)'

    },
    card: {
        position: 'relative',

    },
}

export default function RecipeReviewCard(props) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card className={classes.root}  >

            <CardHeader  classes={{
                title: classes.title,
                content: classes.content
            }}
                title={props.question}
            />
            <Box style={styles.card}>
            <CardMedia
                className={classes.media}
                image={props.image}
                title={props.question}
            />
            <Box style={styles.overlaybg}></Box>
            <div style={styles.overlay}>
                <Box>{props.gram}</Box>
                <Box style={{fontSize:12}}>{props.t('weight')}</Box>
            </div>
            </Box>
            <CardActions disableSpacing className={classes.root}>
                <Typography onClick={handleExpandClick} align={"left"} style={{paddingLeft:"10px"}} variant="body2" color="textSecondary" component="p">
                   {props.t('more_information')}
                </Typography>
                <IconButton
                    className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded,
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="mehr"
                >
                    <ExpandMoreIcon/>
                </IconButton>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent align={"left"} >
                    <Typography variant={'body2'} paragraph>{props.t('ingredients')}:</Typography>
                    <Typography  variant={'body2'} paragraph>
                        {props.details}
                    </Typography>

                </CardContent>
            </Collapse>
        </Card>
    );
}
