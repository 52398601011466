import React from 'react';
import ReactDOM from 'react-dom';
import './style.css';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import * as serviceWorker from './serviceWorker';
import QuizApp from "./components/QuizApp";
import theme from './theme';
import ButtonAppBar from "./components/menubar";
import Container from "@material-ui/core/Container";
import './i18n';

import ReactPixel from 'react-facebook-pixel';
import './i18n';

import ReactGA from 'react-ga';
ReactGA.initialize('UA-103646356-2');
ReactGA.pageview(window.location.pathname + window.location.search);



const options = {
    autoConfig: true, // set pixel's autoConfig
    debug: false, // enable logs
};
ReactPixel.init('562780304525431', options);
ReactPixel.pageView(); // For tracking page view

ReactDOM.render(
  <React.StrictMode>
      <ThemeProvider theme={theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}

          <CssBaseline />

      <QuizApp totalQuestions={5} />
      </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
