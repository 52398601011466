import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import {Link} from "@material-ui/core";

export default function MenuButton(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Button variant="contained"  color="primary" aria-haspopup="true" onClick={handleClick}>
                <Typography variant="h6" style={{color:'#000'}}>
                  Carb Quiz
                </Typography>
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <a style={{textDecoration:"none", color:"#000"}} target={"_blank"} href={'https://heymeala.com/'}> <MenuItem  onClick={handleClose} >{props.t('about')}</MenuItem></a>
               <MenuItem  onClick={() => window.location.reload()} >{props.t('restart')}</MenuItem>

                <a style={{textDecoration:"none", color:"#000"}} target={"_blank"} href={'https://heymeala.com/contact/'}> <MenuItem  onClick={handleClose} >{props.t('contact')}</MenuItem></a>
                <a style={{textDecoration:"none",color:"#353531"}} target={"_blank"} href={'https://www.heymeala.com/impressum/'}><MenuItem onClick={handleClose}>{props.t('imprint')}</MenuItem></a>


            </Menu>
        </>
    );
}
