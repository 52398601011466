import React from 'react';
import PropTypes from 'prop-types';
import QuestionList from './QuestionList';
import Box from '@material-ui/core/Box';
import ButtonAppBar from "./menubar";


const Quiz = ({step, questions, totalQuestions, score, handleAnswerClick, handleEnterPress, t}) => {
    return (
        <>

            <ButtonAppBar
                step={step}
                totalQuestions={totalQuestions}
                score={score}
                t={t}
            />
            <Box className="questions">
                <QuestionList
                    questions={questions}
                    handleAnswerClick={handleAnswerClick}
                    handleEnterPress={handleEnterPress}
                    t={t}
                />
            </Box>
        </>

    );
}

Quiz.propTypes = {
    step: PropTypes.number.isRequired,
    questions: PropTypes.array.isRequired,
    totalQuestions: PropTypes.number.isRequired,
    score: PropTypes.number.isRequired,
    handleAnswerClick: PropTypes.func.isRequired,
    handleEnterPress: PropTypes.func.isRequired
};

export default Quiz;
