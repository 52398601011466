import React from 'react';
import {Box, createStyles, makeStyles} from '@material-ui/core';
import i18next from "i18next";
import Typography from "@material-ui/core/Typography";
import AppStoreButtons from "./AppStoreButtons";
import Container from "@material-ui/core/Container";
import MediaCard from "./MediaCard";
import kevin from "../media/kevin.jpg";

const About = () => {
    const classes = useStyles()

    return (
        i18next.languages[0] === "en" ?
            <>
                <Box maxWidth={300} style={{paddingTop: 40, paddingBottom: 20}}>
                    <Typography variant="body1"
                                align={"center"}>
                        Download the carb quiz app and get new questions every day
                    </Typography>
                </Box>
                <AppStoreButtons/>
                <Container style={{paddingTop: 50,}} maxWidth="sm">
                    <MediaCard image={kevin} title={'Kevin'}/>
                    <Box p={2} fontFamily="Monospace">
                        <Typography variant="body2">
                            Hey, my name is Kevin Röhl - I am a designer, developer and was diagnosed with
                            diabetes 12 years ago. In my opinion the biggest challenge
                            in diabetes is estimating carbohydrates. Therefore I have developed this
                            Carb game. I cooked, weighed and ate all the meals with my girlfriend :) <br/><br/>
                            Did you like the game? Download the meala app for more questions.
                        </Typography></Box>
                </Container>
            </>

            :
            <>
                <Box maxWidth={300} style={{paddingTop: 40, paddingBottom: 20}}> <Typography variant="body1"
                                                                                             align={"center"}>Das
                    ganze Quiz mit
                    täglich neuen Fragen jetzt herunterladen</Typography></Box>
                <AppStoreButtons/>
                <Container style={{paddingTop: 50,}} maxWidth="sm">
                    <MediaCard image={kevin} title={'Kevin'}/>
                    <Box p={2} fontFamily="Monospace">
                        <Typography variant="body2">
                            Hey, mein Name ist Kevin Röhl – Ich bin Designer, Entwickler und habe seit 15
                            Jahren Diabetes. Meiner Meinung nach entstehen die größten Fehler
                            in der Diabetes-Therapie beim Schätzen von Kohlenhydraten. Daher habe ich dieses
                            Carb-Spiel entwickelt. Alle Gerichte habe ich mit meiner Freundin gekocht,
                            gewogen und gegessen :) <br/><br/>
                        </Typography></Box>
                </Container>
            </>

    );
};

export default About;

const useStyles = makeStyles((theme) =>
    createStyles({
        container: {},
    })
);
