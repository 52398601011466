import React from 'react';
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import Answer from './Answer';
import Box from "@material-ui/core/Box";
import RecipeReviewCard from "./card";

const Question = ({question, image, details,gram, answers, handleAnswerClick, handleEnterPress,t}) => {

    return (

        <li className="question">
            <Box align={"center"}>
                <RecipeReviewCard
                    question={question}
                    image={image}
                    details={details}
                    gram={gram}
                    t={t}
                />
            </Box>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
            >
                <ul className="question-answers" tabIndex="-1">
                    {answers.map((answer, index) => {
                        return (
                            <Answer
                                key={JSON.stringify(answer.props.children)}
                                answer={answer}
                                handleAnswerClick={handleAnswerClick(index)}
                                handleEnterPress={handleEnterPress(index)}
                                t={t}
                            />
                        );
                    })}
                </ul>
            </Grid>
        </li>


    );
}

Question.propTypes = {
    question: PropTypes.element.isRequired,
    image: PropTypes.string.isRequired,
    answers: PropTypes.array.isRequired,
    handleAnswerClick: PropTypes.func.isRequired,
    handleEnterPress: PropTypes.func.isRequired
};

export default Question;
