import React, {useState} from 'react';
import PropTypes from 'prop-types';
import tally from '../helpers/tally';
import Container from "@material-ui/core/Container";
import {Box, Button, TextField} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import einstein from '../media/noun_Einstein_16422.svg'
import Grid from "@material-ui/core/Grid";
import ReactGA from 'react-ga';
import {
    FacebookIcon,
    FacebookShareButton,
    TelegramIcon,
    TelegramShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from "react-share";

import ReactPixel from 'react-facebook-pixel';
import About from "./About";
import i18next from "i18next";

const Results = ({userAnswers, score, restartQuiz, t}) => {
    const triesTotal = tally(userAnswers);
    const oneTry = triesTotal[1] && <div><strong>{triesTotal[1]}</strong> Beim ersten Versuch.</div>;
    const twoTries = triesTotal[2] && <div><strong>{triesTotal[2]}</strong> Beim zweiten Versuch.</div>;
    const threeTries = triesTotal[3] && <div><strong>{triesTotal[3]}</strong> Beim dritten Versuch.</div>;
    const fourTries = triesTotal[4] && <div><strong>{triesTotal[4]}</strong> Beim vierten Versuch.</div>;
    const [value, setValue] = useState(null)
    const [error, setError] = useState(false)
    const [success,setSuccess]=useState(false)
    const language =   i18next.languages[0]

    function ValidateEmail(value) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
            return (true)
        }
        //alert("Bitte gib eine E-Mail Adresse ein")
        setError(true)
        return (false)
    }

    function sendEmail(id) {
        console.log(value)
        if (value && ValidateEmail(value)) {
            fetch('https://api.newsletter2go.com/forms/submit/' + id, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({"recipient": {"email": value,}})
            }).then(function (response) {
                    if (response.status !== 200 || response.status !== 201) {
                        console.log('Looks like there was a problem. Status Code: ' +
                            response.status);
                    }
                    setSuccess(true)
                    // Examine the text in the response
                    response.json().then(function (data) {
                        console.log(data);
                    });
                }
            )
                .catch(function (err) {
                    console.log('Fetch Error :-S', err);
                });
        } else {
            setError(true)
        }
    }

    // const avarage =   score > 35 ? <Box align={"center"}><Box ><img src={einstein} height={150} /></Box><br/> {t('expert')}</Box> : null
    const avarage = score >= 27 ?
        <Box align={"center"}>
            <Box>
                <img src={einstein} className="roll-in-left" style={{padding: 0}} height={70} alt={'Wie Einstein :)'}/>
            </Box>
            <Box maxWidth={300}>
                {t("veryGood")}
            </Box>
            {language === "de" ?
                <>
                    <Box m={2}>
                        {success && <Typography h3>Vielen Dank, bestätige die E-Mail die wir gerade gesendet haben</Typography>}
                        <TextField
                            type={'email'}
                            error={error}

                            helperText={error && 'Überprüfe deine E-Mail Adresse'}
                            placeholder={"E-Mail Adresse"}
                            value={value}
                            onChange={(event) => setValue(event.target.value)}
                            variant="filled"/>
                    </Box>
                    <Button style={{backgroundColor: '#2642b4', color: '#fff'}} onClick={() => sendEmail('ur1juhg3-rfnjv1w3-i4q')}
                            variant={'contained'}>Mehr zu Level 2</Button>

                </>
                :      <>
                    <Box m={2}>
                        {success && <Typography h3>Thanks, check your E-Mails</Typography>}
                        <TextField
                            type={'email'}
                            error={error}

                            helperText={error && 'Double check your E-Mail'}
                            placeholder={"E-Mail address"}
                            value={value}
                            onChange={(event) => setValue(event.target.value)}
                            variant="filled"/>
                    </Box>
                    <Button style={{backgroundColor: '#2642b4', color: '#fff'}} onClick={() => sendEmail('ur1juhg3-m3b64h93-fgu')}
                            variant={'contained'}>Get Infos on Level 2</Button>

                </>
            }
            <br/>
            <About/>
        </Box>
        :
        <Box align={"center"}><Box maxWidth={300}>{t('average')}</Box><br/>
            <Box>
                <a style={{padding: 8,}} onClick={restartQuiz}>{t('restart')}</a>
            </Box>
            <About/>
        </Box>
    ReactGA.event({
        category: 'User',
        action: 'Result Score',
        label: 'Score ' + score.toString(),
        value: 1,
        nonInteraction: true
    });
    ReactPixel.track('ScoreScreen', {
        content_name: 'Played Through',
        content_category: 'Level 1',
    })


    return (
        <Container maxWidth="md">
            <Box className="results-container">
                <Typography variant="h6">Quiz {t('results')}</Typography>
                <Box> <Typography variant="h4"><strong>{score}</strong> {t('Points')}</Typography></Box>
                <br/>
                <Box> {avarage}</Box>
                <Box p={1}> <Typography variant="h6" align={"center"}>{t('share')}</Typography></Box>
                <Grid container justify="center" spacing={2}>
                    <Grid item>
                        <WhatsappShareButton url={t('url')} title={t('share_text1') + score + t('share_text2')}>
                            <WhatsappIcon/>
                        </WhatsappShareButton>
                    </Grid>
                    <Grid item spacing={2}>
                        <FacebookShareButton
                            url={t('url')}
                            quote={t('share_text1') + score + t('share_text2')}>
                            <FacebookIcon/>
                        </FacebookShareButton></Grid>
                    <Grid item>
                        <TelegramShareButton
                            url={t('url')}
                            title={t('share_text1') + score + t('share_text2')}>
                            <TelegramIcon/>
                        </TelegramShareButton>
                    </Grid>
                </Grid>

                <a style={{padding: 12,}} onClick={restartQuiz}>{t("restart")}</a>
            </Box>


        </Container>
    );
}

Results.propTypes = {
    userAnswers: PropTypes.array.isRequired,
    score: PropTypes.number.isRequired,
    restartQuiz: PropTypes.func.isRequired
};

export default Results;
