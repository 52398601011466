import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

import CardMedia from '@material-ui/core/CardMedia';


const useStyles = makeStyles({
    root: {

        width: '80%',

        margin: 'auto',


    },
    media: {
        height: 350,



    },
});

export default function MediaCard(props) {
    const classes = useStyles();

    return (
        <Card className={classes.root}>

                <CardMedia
                    className={classes.media}
                    image={props.image}
                    title={props.title}

                />

        </Card>
    );
}
