import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Secular One',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif'
    ].join(','),
    body2: {
      fontFamily: 'Roboto',
      fontWeight: 500,
    },

  },

  palette: {
    primary: {
      main: '#ffd900',
    },
    secondary: {
      main: '#000',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#ffd900',
    },
  },

});

export default theme;
