import React from 'react';
import {createStyles, makeStyles} from '@material-ui/core';
import Grid from "@material-ui/core/Grid";
import ReactGA from "react-ga";
import Apple from "../media/Download_on_the_App_Store_Badge_de_135x40.svg";
import Android from "../media/de_get.svg";


const AppStoreButtons = () => {
    const classes = useStyles()

    return (
        <Grid container justify="center" spacing={2}>
            <Grid item>
                <ReactGA.OutboundLink
                    eventLabel='Apple_Store' target='_blank'
                    style={{border: 0}}
                    to="https://apps.apple.com/de/app/meala/id1477171627">
                    <img
                        src={Apple}
                        height={50} alt={'iOS App Store'}
                    />
                </ReactGA.OutboundLink></Grid>
            <Grid item>
                <ReactGA.OutboundLink
                    eventLabel='Android_Store' target='_blank'
                    style={{border: 0}}
                    to="https://play.google.com/store/apps/details?id=com.heymeala&utm_source=quiz">
                    <img height={50} src={Android} alt={'Android Play Store'}/>
                </ReactGA.OutboundLink></Grid>
        </Grid>
    );
};

export default AppStoreButtons;

const useStyles = makeStyles((theme) =>
    createStyles({
        container: {},
    })
);
