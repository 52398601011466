import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Box from "@material-ui/core/Box";
import Slide from '@material-ui/core/Slide';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import PropTypes from 'prop-types';
import MenuButton from "./menuButton";
import CountUp from "react-countup";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,

    },
    appBar: {
        top: 'auto',
        bottom: 0,
    },
}));

function HideOnScroll(props) {
    const {children,} = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger();

    return (
        <Slide appear={false} direction="up" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,

};


export default function ButtonAppBar(props) {
    const classes = useStyles();

    return (
        <React.Fragment>
            <div className={classes.root}>
                <HideOnScroll {...props}>
                    <AppBar style={{backgroundColor: '#2E3B55', color: '#fff'}} position="fixed"
                            className={classes.appBar}>
                        <Toolbar>
                            <Box align={"center"}>

                                {props.t('Question')} {props.step}
                            <Box>  {props.t('of')}  {props.totalQuestions} </Box>
                        </Box>
                        <>
                        <Typography variant="h4" className={classes.title}>

                                    <Box align={"center"}> <MenuButton t={props.t}/></Box>

                                </Typography>
                            </>
                            <Box align={"center"}>

                            <Box><CountUp duration={1}   end={props.score} /></Box>

                            <Box> {props.t('Point')} </Box>
                        </Box>

                        </Toolbar>
                    </AppBar>
                </HideOnScroll>
            </div>
        </React.Fragment>
    );
}
