import React from 'react';
import pizza from '../media/readypizza.png';
import pasta from '../media/nudeln_pesto.png';
import pommes from '../media/pommes.png';
import bbq from '../media/BBQ.png';
import springrolles from '../media/springrolles.png';
import ruebli from '../media/ruebli.png';
import quiche from '../media/quiche.png';
import zimtkuchen from '../media/zimtkuchen.png';
import gimbap from '../media/gimbap.png';
import tortelini from '../media/tortelini.png';

const QUESTION_DATA = [
  {
    question: <span>Rübli-Kuchen</span>,
    image: ruebli,
    details: <span>Ein Kuchen der im Vergleich zu anderen weniger Kohlenhydrate hat, da er ohne Mehl gebacken wird. Er enthält vorallem viele Nüsse.
    Das Frosting besteht aus Puderzucker und Frischkäse</span>,
    gram: <span>91g</span>,
    answers: [
      <span>6g Kohlenhydrate</span>,
      <span>24g Kohlenhydrate</span>,
      <span>53g Kohlenhydrate</span>,
        ],
    correct: 1
  },
  {
    question: <span>1,5 Frühlingsrollen</span>,
    image: springrolles,
    details: <span>Die Frühlingsrollen bestehen aus Reispapier und enthalten Glasnudeln und Möhren. Zusätzlich enthält die Schale Reisnudeln, welche
      einen einen sehr hohen Glygämischen-Index haben.</span>,
    gram: <span>338g</span>,
    answers: [
      <span>48g Kohlenhydrate</span>,
      <span>24g Kohlenhydrate</span>,
      <span>124g Kohlenhydrate</span>,
    ],
    correct: 0
  },
  {
    question: <span>Nudelsalat mit Baguette und Bratwurst</span>,
    image: bbq,
    details: <span>Nudelsalat mit Baguette, Gemüse und Bratwurst vom Grill. Soßen oder Ketchup ist in diesem Gericht noch nicht enthalten.</span>,
    gram: <span>269g</span>,
    answers: [
      <span>34g Kohlenhydrate</span>,
      <span>94g Kohlenhydrate</span>,
     <span>77g Kohlenhydrate</span>
    ],
    correct: 2
  },
  {
    question: <span>Pommes & Kroketten</span>,
    image: pommes,
    details: <span>Diese Pommes und Kroketten von Rewe haben auf 100g gerechnet ungefähr die gleiche Menge an Kohlenhydrate.
      Die Portion auf dem Fotos ist relativ groß und reicht für ca. zwei kleine Portionen.</span>,
    gram: <span>294g</span>,
    answers: [
      <span>39g Kohlenhydrate</span>,
      <span>94g Kohlenhydrate</span>,
      <span>184g Kohlenhydrate</span>,
      <span>73g Kohlenhydrate</span>
    ],
    correct: 3
  },
  {
    question: <span> 1/8 Quiche</span>,
    image: quiche,
    details: <span>Die Quiche besteht aus viel Gemüse und wird gehalten durch eine Teigschicht aus Mehl. Weitere Zutaten sind Eier und Schmand.</span>,
    gram: <span>245g</span>,
    answers: [
      <span>39g Kohlenhydrate</span>,
      <span>53 g Kohlenhydrate</span>,
      <span>22g Kohlenhydrate</span>,
      <span>8g Kohlenhydrate</span>
    ],
    correct: 2
  },
  {
    question: <span>Pizza (Steinofen)</span>,
    image: pizza,
    details: <span>Pizzen bestehen aus vielen Kohlenhydraten durch die hohe Menge an Teig. Doch sie enthalten auch viel Fett durch den Käse und
    in diesem Fall zusätzlich wegen der Salamie und dem Schinken. Eine Kombination die das Verhalten des Blutzuckerspiegels komplizierter macht.</span>,
    gram: <span>350g</span>,
    answers: [
      <span>39g Kohlenhydrate</span>,
      <span>94g Kohlenhydrate</span>,
      <span>84g Kohlenhydrate</span>

    ],
    correct: 2
  },
  {
    question: <span>Nudeln mit Pesto</span>,
    image: pasta,
    details: <span>Ein sehr einfaches und schnelles Gericht. 250g gekochte
Bandnudeln aus Hartweizengrieß und zwei Eiern mit Brailla Pomodoro Secchi Pesto und dazu ein Tomaten Mozzarella Salat.</span>,
    gram: <span>250g</span>,
    answers: [

      <span>104g Kohlenhydrate</span>,
      <span>144g Kohlenhydrate</span>,
      <span>77g Kohlenhydrate</span>
    ],
    correct: 2
  },
  {
    question: <span>Gimbap</span>,
    image: gimbap,
    details: <span><p>Gimbap (auch Kimbap, Kim-Bab, Gim-Bab) ist ein populärer koreanischer Imbiss, der teilweise auch als
      Mittagessen verzehrt wird. Es besteht aus getrocknetem Purpurtang (kor.: Gim) und Reis (kor. Bab =
      gekochter Reis), sowie je nach Variation weiteren Zutaten wie Bulgogi, Spinat, eingelegten Rettich
      (kor.: Danmuji 단무지) und Omelette. Das koreanische „gim“ ähnelt in Farbe und Geschmack sehr dem japanischen „Nori“.</p>
    <br/>
    <p>
    Meistens wird
der Reis sehr weich gekocht, dadurch hat er einen
höheren Glykämischen Index. Dies bedeutet, dass der Zucker sehr schnell ins Blut gelangt.</p> </span>,
    gram: <span>370g</span>,
    answers: [
      <span>121g Kohlenhydrate</span>,
      <span>24g Kohlenhydrate</span>,
      <span>91g Kohlenhydrate</span>,
      <span>27g Kohlenhydrate</span>
    ],
    correct: 2
  },
  {
    question: <span>Tortellini</span>,
    image: tortelini,
    details: <span>Tortellini mit Käsefüllung, Zucchini, Tomaten und Pilzen</span>,
    gram: <span>125g</span>,
    answers: [
      <span>39g Kohlenhydrate</span>,
      <span>95g Kohlenhydrate</span>,
      <span>54g Kohlenhydrate</span>

    ],
    correct: 0
  },

  {
    question: <span>Zimtkranz</span>,
    image: zimtkuchen,
    details: <span>Der Zimtkranz ist  ein abgewandeltes Franzbrötchenrezept mit Dinkelmehl, Weizenmehl, Zimt und braunem Zucker</span>,
    gram: <span>108g</span>,
    answers: [
      <span>22g Kohlenhydrate</span>,
      <span>56g Kohlenhydrate</span>,
      <span>87g Kohlenhydrate</span>
    ],
    correct: 1
  }
];

export default QUESTION_DATA;
